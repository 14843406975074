<template>
<div>
    <main>
        <slot/>
    </main>
    <footer></footer>
</div>
</template><script setup>
    import { computed,watch } from 'vue';





            


    

    

    //replaceMome
    

    
    

    const $device = useDevice();
    const { isMobile,isDesktop } = useDevice();
    
    
    


    









    //   variables
    if(process.env.NODE_ENV==='development'){

    }









    // computed
    

    //attributesCal 0

    


        // computed





        const clearTypeName = (api)=>{
            const funF = (obj)=>{
                if(obj){
                    console.log('obj')
                    Object.keys(obj).forEach((key)=>{
                        if(key==="__typename"){
                            delete obj.__typename;
                        } else if(Array.isArray(obj[key])){
                            obj[key].forEach((item)=>{
                                if(item)
                                    funF(item);
                            });
                        } else if(typeof (obj[key]) ==="object"&&(obj[key])){
                            if(obj[key])
                                funF(obj[key]);
                        }
                    });}
            };
            funF(api);
            return api;
        };

// mutation

        

    


    





        //0 setup
        

        
        







        //0 afterData
        


        


        



        
        const methods=()=>{
            
        }

        





  // watch



    











    //meta
        


</script>


<style  >
    @import "assets/css/apieu.css";
@import "assets/css/ca-nhan.css";
@import "assets/css/cart.css";
@import "assets/css/cosmetic-two.css";
@import "assets/css/dienmay.css";
@import "assets/css/end-june.css";
@import "assets/css/halt-june.css";
@import "assets/css/index.css";
@import "assets/css/layouts.css";
@import "assets/css/mau-u-dai.css";
@import "assets/css/Nav.css";
@import "assets/css/san-pham.css";
@import "assets/css/start-july.css";
@import "assets/css/tapchi.css";
@import "assets/css/version-2.css";

</style>

<!--true-->
<script>


    
    export default {
        name: "authLayout",

        

        data(){
            return {

                }
            },
                methods:{

                runAnimation(name,delay=0,loop=false,reverse=false){

                    
                }



            }

        }
</script>

//update in Mon Jul 15 2024 08:57:25 GMT+0700 (Indochina Time) by loidirieng
